<template>
  <a-modal
    :visible.sync="show"
    title="选择商品"
    destroyOnClose
    width="45%"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="10">
            <a-form-model-item prop="type" label="商品分类">
              <a-select v-model="searchForm.type" placeholder="分类" style="width:100%;">
                <a-select-option value>全部</a-select-option>
                <a-select-option
                  v-for="item in Object.keys(goodsType)"
                  :value="item"
                  :key="item"
                >{{ goodsType[item] }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item prop="goods_name" label="商品名称">
              <a-input allowClear v-model="searchForm.goods_name" placeholder="请输入商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item >
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table
      class="goosTable-class"
      ref="goodsTable"
      :columnsData="columns"
      rowKey="goods_id"
      :row-selection="rowSelection"
      :tableData="tableData"
      :customRow="Rowclick"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :customHeight="300"
      :pageSize.sync="searchForm.page_count"
    >
    <div slot="footer">
      已选<span class="text-red-500">{{ rowSelection.selectedRowKeys.length }}</span>项
    </div>
    </base-table>

    
  </a-modal>
</template>

<script>
import { formatGoodsType, goodsType } from "@/utils/type"
import { getGoodsList } from "@/api/goods" // 如果没有传入的请求列表数据的函数，则默认查询商品库
import _ from "lodash"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    selectKeys: {
      type: Array,
      default: () => [],
    },

    selectRows: {
      type: Array,
      default: () => [],
    },

    getDataList: {
      type: Function,
    },
  },
  data() {
    return {
      goodsType: goodsType,
      wrapperCol: { span: 16 },
      labelCol: { span: 8 },
      searchForm: {
        goods_name: "",
        type: "",
        offers_time_start: "",
        offers_time_end: "",
        page: 1,
        page_count: 30,
        goods_create_time: [],
      },
      columns: [
        // {
        //   title: "商品编码",
        //   dataIndex: "goods_id",
        //   align: "center",
        // },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
        {
          title: "商品分类",
          dataIndex: "goods_type",
          align: "center",
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
      ],
      tableData: [],
      total: 0,
      rows: [],
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.goods_id)
            this.rows.push(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.goods_id
            )
            this.rows = this.rows.filter((el) => el.goods_id != record.goods_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          // this.selectRows = selectedRows
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.goods_id
            )
            this.rows = selectedRows
          } else {
            this.rowSelection.selectedRowKeys = []
            this.rows = []
          }
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              defaultChecked: this.selectKeys.includes(record.goods_id),
            },
          }
        },
      },
    }
  },
  async mounted() {
    this.rowSelection.selectedRowKeys = this.selectKeys
    await this.initData()
    this.rows = _.cloneDeep(this.selectRows)
  },
  methods: {
    async initData() {
      if (this.getDataList) {
        const { data, code } = await this.getDataList(this.searchForm)
        if (code === 0) {
          this.tableData = data.list
          this.total = data.total_count
        }
      } else {
        // 默认查询商品库所有商品
        const { data, code } = await getGoodsList(this.searchForm)
        if (code === 0) {
          this.tableData = data.list.map((el) => {
            el.goods_type = el.type
            return el
          })
          this.total = data.total_count
        }
      }
    },
    // 保存
    handleOk() {
      console.log(this.rows)
      this.$emit("update:show", false)
      this.$emit("ok", this.rows)
    },
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    resetForm() {
      this.searchForm.goods_name = ""
      this.searchForm.type = ""
      this.searchForm.offers_time_start = ""
      this.searchForm.offers_time_end = ""
      this.searchForm.page = 1
      this.searchForm.page_count = 10
      this.searchForm.goods_create_time = []
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    Rowclick(record) {
      return {
        on: {
          click: () => {
            //如果是已选就去掉
            if (this.rowSelection.selectedRowKeys.includes(record.goods_id)) {
              this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.goods_id
              )
              this.rows = this.rows.filter(
                (el) => el.goods_id != record.goods_id
              )
            } else {
              this.rowSelection.selectedRowKeys.unshift(record.goods_id)
              this.rows.push(record)
            }
          },
        },
      }
    },
  },
}
</script>

<style lang="less">
.goosTable-class {
  margin-left: -10px;
  .ant-modal-body {
    position: relative;
    padding-bottom: 10px;
  }
}
</style>