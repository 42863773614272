import { render, staticRenderFns } from "./type-edit-modal.vue?vue&type=template&id=463ae840&"
import script from "./type-edit-modal.vue?vue&type=script&lang=js&"
export * from "./type-edit-modal.vue?vue&type=script&lang=js&"
import style0 from "./type-edit-modal.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports