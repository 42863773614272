import ajax from '@/utils/ajax.js'

/**
 * 获取商品列表
 * @param {*} params 
 * @returns 
 */
export function getGoodsList(params) {
  return ajax.post('/Goods/getList',params)
}

/**
 * 获取商品详情
 * @param {*} params 
 * @returns 
 */
 export function getGoodsDetail(params) {
  return ajax.post('/Goods/detail',params)
}

/**
 * 新增商品
 * @param {*} params 
 * @returns 
 */
export function addGoods(params) {
  return ajax.post('/Goods/add',params)
}
/**
 * 编辑商品
 * @param {*} params 
 * @returns 
 */
export function eidtGoods(params) {
  return ajax.post('/Goods/update',params)
}

/**
 * 删除商品
 * @param {*} params 
 * @returns 
 */
export function updateGoodsStatus(params) {
  return ajax.post('/Goods/updateStatus',params)
}

/**
 * 上架商品到全部网点：蛋糕
 * @param {*} params 
 * @returns 
 */
export function pushCakeToAllOutlets(params) {
  return ajax.post('/Goods/synCakeToAllOutlets',params)
}

/**
 * 上架商品到全部网点：非蛋糕
 * @param {*} params 
 * @returns 
 */
// export function pushCakeToAllOutlets(params) {
//   return ajax.post('/Goods/synOtherToAllOutlets',params)
// }

/**
 * 根据SKUID获取各城市蛋糕制作时间
 * @param {*} params 
 * @returns 
 */
export function getCityProductionTimeList(params) {
  return ajax.post('/OutletsCity/getGoodsSkuProductionTime',params)
}


/**
 * 导出全部商品
 * @param {*} params 
 * @returns 
 */
export function exportGoods(params) {
  return ajax.openurl('/Goods/exportList',params)
}