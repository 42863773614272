export const rowKey = 'goods_number'

export const columns = [ // 门店列表
  {
    title: '商品图片',
    dataIndex: 'cover_url',
    align: 'center',
    width: "10%",
    slots: { customRender: 'cover_url' }
  },
  // {
  //   title: '商品编码',
  //   dataIndex: 'goods_number',
  //   align: 'center',
  // },
  {
    title: '商品名称',
    dataIndex: 'goods_name',
    align: 'center',
  },
  {
    title: '商品分类',
    dataIndex: 'goods_type',
    align: 'center',
    width: "10%",
    slots: {
      customRender: 'type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatType(val)
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
    width: "15%",
    slots: { customRender: 'operation' }
  },
]

export const goodsType = {
  1: '软点',
  2: '西点',
  3: '蛋糕'
}

export function formatType (val) {
  return goodsType[val]
}


export default {
  rowKey,
  columns,
  goodsType
}
