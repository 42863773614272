<template>
  <div>
    <base-table ref="mallListTable"
                id="mallListTable"
                :columnsData="columns"
                :rowKey="rowKey"
                :tableData="tableData"
                :addHeight="-35">
      <template #toolbar>
        <div>
          <h4 class="text-lg font-bold">小程序/网站在售商品列表</h4>
          <div class="flex justify-between">
            <a-tabs type="card"
                    @change="handlerTypeChange"
                    :activeKey="activeKey">
              <a-tab-pane key="" tab="全部"></a-tab-pane>
              <a-tab-pane v-for="item in typeList"
                          :key="item.type_id"
                          :tab="item.type_name"></a-tab-pane>
              <div slot="tabBarExtraContent">
                <a-button type="primary"
                          @click="handlerEditType"
                          html-type="submit"
                          style="margin-left:15px;">编辑分类</a-button>
              </div>
            </a-tabs>
          </div>
          <div class="flex justify-between">
            <div>
            </div>
            <div>
              <template v-if="!isEdit">
                <a-button type="primary" @click="handlerEditGoods">编辑商品</a-button>
              </template>
              <template v-else>
                <a-button v-if="activeKey" type="primary" 
                          @click="isShowAddGoods = true">添加商品</a-button>
                <a-button class="ml-3" type="danger"
                          @click="handlerEditGoodsSave">保存</a-button>
                <a-button class="ml-3" type="dashed"
                          @click="handlerEditGoodsCancel">取消</a-button>
              </template>
            </div>
          </div>
        </div>
      </template>

      <template #cover_url="{text}">
        <img :src="text+cutQuery(50,50)"
             style="height:50px;" />
      </template>

      <template #operation="{record,index}">
        <a-button type="link"
                  @click="handlerDetail(record.goods_id)">详情</a-button>
        <a-button type="link"
                  v-if="isEdit"
                  @click="handlerDelete(index)">删除</a-button>
      </template>
    </base-table>
    <type-edit-modal v-if="isShowType"
                     :show.sync="isShowType"
                     :typeList="typeList"
                     @ok="initData" />
    <add-goods-modal v-if="isShowAddGoods"
                     :typeId="activeKey"
                     :show.sync="isShowAddGoods"
                     :selectKeys="selectKeys"
                     :selectRows="tableData"
                     @ok="handlerAddGoodsOk" />
  </div>
</template>

<script>
import Sortable from "sortablejs"

import TypeEditModal from "../components/type-edit-modal.vue"

import AddGoodsModal from "@/components/select-modal/add-goods.vue"

import pageData from "./columns"
import { filterList } from '@/utils/index'
import { cutQuery } from '@/utils/ossImg'

import {
  getTypeList,
  getGoodsListByTypeId,
  getGoodsListAll,
  updateGoodsListAllSort,
  editTypeGoods,
  getSkuListByGoodsId
} from "@/api/shop.js"

export default {
  components: {
    TypeEditModal,
    AddGoodsModal,
  },
  data () {
    return {
      ...pageData,
      isShowType: false,
      isEdit: false,
      isShowAddGoods: false,
      activeKey: "",
      total: 0,
      typeList: [],
      tableData: [],
      selectKeys: [],

      sort_table: null,
      loading: false,
    }
  },
  async mounted () {
    this.initData()
  },
  methods: {
    cutQuery,
    async initData () {
      const { data, code } = await getTypeList()
      if (code == 0) {
        this.typeList = data.list
        // 默认选中
        if (this.$route.query.typeId) {
          this.activeKey = parseInt(this.$route.query.typeId)
          await this.initGoodsData()
          this.isEdit = true
          this.getGoodsList(this.$route.query.goodsId)
        } else {
          this.initGoodsData()
        }
      }
    },

    async getGoodsList (ids) {
      getSkuListByGoodsId({ goods_ids: ids }).then(res => {
        if (res.code == 0) {
          this.tableData = filterList(res.data.list.concat(this.tableData), 'goods_id')
          this.selectKeys = this.tableData.map((el) => {
            return el.goods_id
          })
        }

      })
    },
    async initGoodsData () {
      this.isEdit = false
      this.stopRowDrop()
      let res = null
      if(this.activeKey){
        res = await getGoodsListByTypeId({ type_id: this.activeKey })
      }else{
        res = await getGoodsListAll()
      }
      this.tableData = res.data.list
      this.selectKeys = res.data.list.map((el) => {
        return el.goods_id
      })
    },
    handlerTypeChange (e) {
      this.activeKey = e
      this.initGoodsData()
    },

    handlerEditGoods () {
      this.isEdit = true
      this.$nextTick(() => {
        this.rowDrop()
      })
    },

    handlerAddGoodsOk (keys) {
      this.selectKeys = keys.map(el => el.goods_id)
      this.tableData = keys.reverse()
    },
    handlerEditGoodsSave () {
      if(this.loading) return

      const params = { type_id: this.activeKey }
      params.goods_data = JSON.stringify(
        this.selectKeys.map((item) => {
          return {
            goods_id: item,
          }
        })
      )
      this.loading = true
      if(this.activeKey){
        editTypeGoods(params).then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success("编辑商品成功！")
            this.initGoodsData()
            this.isEdit = false
            this.stopRowDrop()
          }
        })
      }else{
        updateGoodsListAllSort(params).then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success("已保存排序")
            this.initGoodsData()
            this.isEdit = false
            this.stopRowDrop()
          }
        })
      }
    },
    handlerEditGoodsCancel () {
      this.isEdit = false
      this.stopRowDrop()
    },
    handlerDelete (index) {
      this.tableData.splice(index, 1)
      this.selectKeys.splice(index, 1)
    },
    handlerDetail (id) {
      // this.$router.push({
      //   path: "/goods-edit",
      //   query: { type: "detail", id: id },
      // })
      const newPage = this.$router.resolve({
        path: "/goods-edit",
        query: { type: "detail", id: id },
      })
      window.open(newPage.href, "_blank")
    },
    handlerEditType () {
      this.isShowType = true
    },
    //行拖拽
    rowDrop () {
      if(this.sort_table){
        this.sort_table.destroy()
        this.sort_table = null
      }
      const tbody = document.querySelector(
        "#mallListTable .ant-table .ant-table-body .ant-table-tbody"
      )
      const _this = this
      this.sort_table = Sortable.create(tbody, {
        group: ".ant-table-row",
        handle: ".ant-table-row",
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0]
          const currKey = _this.selectKeys.splice(oldIndex, 1)[0]
          _this.tableData.splice(newIndex, 0, currRow)
          _this.selectKeys.splice(newIndex, 0, currKey)
        },
      })
    },
    stopRowDrop(){
      if(this.sort_table){
        this.sort_table.destroy()
        this.sort_table = null
      }
    },
  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>